import React from 'react'
import PartnerCarousel from './PartnerCarousel'

const Partners = () => {
    return (
        <section className="relative" id="partners">
            <div className='max-w-[1446px] px-[40px] mx-auto z-1'>
                <h3 className='h3 gradient-text w-fit mb-3'>Our Partners</h3>
            </div>
            <PartnerCarousel />
        </section>
    )
}

export default Partners
