import React from 'react'

const PartnerCarousel = () => {
    const logos = [
        // '/images/codeflix-logo.png',
        // '/images/deadlycoders-logo.svg',
        '/images/kaz-robotics-logo.svg',
        '/images/your-angel-logo.png',
        '/images/kamalei-logo-bgremoved.png',
        '/images/koori-kulcha-logo.png',
        '/images/kaz-incursions.png',
        '/images/kazpossums.svg',
        '/images/kazacosai-logo-gradient-black-text.png',
        // '/images/taurus-marketing-logo.svg',
    ]

    return (
        <div className='relative z-[1] bg-kaz-blue bg-opacity-3 border-2 border-l-0 border-r-0 border-solid border-kaz-blue border-opacity-40 w-screen py-6 md:py-12'>
            <div className="flex w-full overflow-hidden">
                <div className="flex animate-partnerScroll">
                    {logos.concat(logos).map((logo, index) => (
                    <div key={index} className="items-center justify-center flex grow-0 shrink-0 basis-[auto] w-1/5 mx-4">
                        <img src={logo} alt={`Logo ${index + 1}`} className='max-h-[100px]'/>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PartnerCarousel
