import React, { useState, useEffect } from 'react'
import Spline from '@splinetool/react-spline'

const HeroShape = () => {
    const [loading, setLoading] = useState(true)
    
    const handleLoad = (spline) => {
        setTimeout(() => {
            const camera = spline.findObjectById('ad49bc85-b31e-46dd-8abe-6ce721e37f2c')
            const handleResize = () => {
                if(camera) {
                    if(window.innerWidth < 576) {
                        camera.position.x = -10
                        camera.scale.x = 1.6
                        camera.scale.y = 1.6
                        camera.scale.z = 1.6
                    } else if(window.innerWidth < 768) {
                        camera.position.x = -10
                        camera.scale.x = 1.5
                        camera.scale.y = 1.5
                        camera.scale.z = 1.5
                    } else if(window.innerWidth < 880) {
                        camera.position.x = -850
                        camera.scale.x = 1.6
                        camera.scale.y = 1.6
                        camera.scale.z = 1.6
                    } else if(window.innerWidth < 1024) {
                        camera.position.x = -900
                        camera.scale.x = 1.5
                        camera.scale.y = 1.5
                        camera.scale.z = 1.5
                    } else if(window.innerWidth < 1280) {
                        camera.position.x = -1000
                        camera.scale.x = 1.3
                        camera.scale.y = 1.3
                        camera.scale.z = 1.3
                    } else {
                        camera.position.x = -890
                        camera.scale.x = 1
                        camera.scale.y = 1
                        camera.scale.z = 1
                    }
                }
            }
            window.addEventListener('resize', handleResize)
            handleResize()
            setLoading(false)
        }, 0)
    }

    return (
        <div className='relative w-full h-full min-w-[400px] md:min-w-[initial] min-h-[450px] md:min-h-[initial] md:static flex-1 flex items-center justify-center'>
            {loading && <div className='spinner'/>}
            <div className='absolute inset-0 w-full h-full z-[-1]'>
                <Spline
                    onLoad={handleLoad}
                    className='animate-fadeIn'
                    style={{pointerEvents: 'none', opacity: loading ? '0' : '1'}}
                    scene="https://prod.spline.design/UbZnDV5X22hScVwc/scene.splinecode"
                />
            </div>
        </div>
    )
}

export default HeroShape
