import React from 'react'

const FormField = ({ fieldName, fieldLabel, value, handleChange, errors, type='text' }) => {

    return (
        <div className='flex flex-col w-full pb-6'>
            <label className='h4 pl-6 pb-1 tracking-wide'>{fieldLabel} <span className='text-kaz-green text-[18px]'>*</span></label>
            { fieldName === 'message' ? (
                <textarea
                    name={fieldName}
                    value={value}
                    onChange={handleChange}
                    className='p bg-kaz-blue bg-opacity-5 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] px-5 py-3 min-h-[200px] transition-colors duration-300 ease-out hover:bg-opacity-[0.15] focus:border-opacity-100 focus:outline-none focus:bg-opacity-[0.15]'
                    required
                />
            ) : (
                <input
                    type={type}
                    name={fieldName}
                    value={value}
                    onChange={handleChange}
                    className='p bg-kaz-blue bg-opacity-5 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] px-5 py-3 transition-colors duration-300 ease-out hover:bg-opacity-[0.15] focus:border-opacity-100 focus:outline-none focus:bg-opacity-[0.15]'
                    required
                />
            )}
            {errors && <p className='p-small font-normal pl-6 pt-1 text-kaz-red animate-fadeIn'>{errors}</p>}
        </div>
    )
}

export default FormField
