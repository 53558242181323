import React from 'react'
import { Email } from 'react-obfuscate-email'

const Footer = () => {
    return (
        <div className='container'>
            <footer className="bg-[#fff] pl-8 pr-8 sm:pr-4 py-3 flex gap-[20px] items-center relative border-2 border-solid border-kaz-blue border-opacity-40 rounded-full">
                <div className="flex-1">
                    <img src="/images/kpowerlogo-2.png" alt="KPower" className="w-auto h-[64px]" />
                </div>
                <p className='p-small flex-1 text-right sm:text-center'>
                    <div>&copy; 2006 - 2024 KPOWER PTY LTD. All rights reserved.</div>
                    <div className="text-xs mx-auto">Developed by <a href="https://kazacos.ai/">kazacos.ai</a></div>
                </p>
                <div className='flex-1 justify-end hidden sm:flex'>
                    {/* <a href="#contact-us"> */}
                    <Email email="leah@kazcare.com"><button className='btn-small btn-primary md:mr-0'>Make an Inquiry</button></Email>
                    {/* </a> */}
                </div>
            </footer>
        </div>
    )
}

export default Footer
