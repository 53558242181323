import React, { useState } from 'react'
import { Email } from 'react-obfuscate-email'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleNavbar = () => {
        setIsOpen(!isOpen)
    }

    const closeNavbar = () => {
        setIsOpen(false)
    }

    return (
        <div className='container'>
            <nav className="bg-[#fff] pl-8 pr-4 py-3 flex items-center justify-between relative border-2 border-solid border-kaz-blue border-opacity-40 rounded-full">
                <a href="/" className="flex items-center">
                    <img src="/images/kpowerlogo-2.png" alt="KPower" className="w-auto h-[64px]" />
                </a>
                <button onClick={toggleNavbar} className="md:hidden p-2 rounded-md focus:outline-none focus:none">
                    <div className="w-6 h-6 flex flex-col justify-around items-center">
                        <span className={`block w-full h-0.5 bg-kaz-green transform transition-all duration-300 ease-in-out ${isOpen ? 'rotate-45 translate-y-2 translate-x-0' : 'rotate-0 translate-y-0 translate-x-0'}`}></span>
                        <span className={`block w-full h-0.5 bg-kaz-green transform transition-opacity duration-300 ease-in-out ${isOpen ? 'opacity-0' : 'opacity-100'}`}></span>
                        <span className={`block w-full h-0.5 bg-kaz-green transform transition-all duration-300 ease-in-out ${isOpen ? '-rotate-45 -translate-y-2 translate-x-0' : 'rotate-0 translate-y-0 translate-x-0'}`}></span>
                    </div>
                </button>
                <div className={`${isOpen ? 'flex' : 'hidden'} md:flex flex-col md:flex-row md:items-center absolute md:relative top-full md:top-0 right-0 mt-[2px] md:mt-0 py-2 md:py-0 w-[200px] md:w-auto bg-[#0c191f] md:bg-opacity-0 z-50 animate-fadeIn md:animate-none`}>
                    {/* <a href="#services-summary" onClick={closeNavbar} className="mx-1 lg:mx-2 p-3 nav transition-[color] duration-200 ease-in-out hover:text-kaz-green">Services</a> */}
                    {/* <a href="#products" onClick={closeNavbar} className="mx-1 lg:mx-2 p-3 nav transition-[color] duration-200 ease-in-out hover:text-kaz-green">Products</a> */}
                    <a href="#partners" onClick={closeNavbar} className="mx-1 lg:mx-2 p-3 nav transition-[color] duration-200 ease-in-out hover:text-kaz-green">Affiliates</a>
                    <a href="#about" onClick={closeNavbar} className="mx-1 lg:mx-2 p-3 nav transition-[color] duration-200 ease-in-out hover:text-kaz-green">About</a>
                    <Email email="leah@kazcare.com"><button className='btn-small btn-primary md:mr-0'>Make an Inquiry</button></Email>
                </div>
            </nav>
        </div>
    )
}

export default Navbar
