import React from 'react'

const About = () => {
    return (
        <section className="container relative mb-[60px] md:mb-[90px]" id="about">
            <h1 className='h1-large text-center mb-10'>About</h1>
            {/* our story section */}
            <div className='flex flex-col-reverse md:flex-row bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mb-6 md:h-[400px]'>
                <div className='flex-1 flex flex-col justify-center'>
                    <div className='max-w-[620px] mx-auto p-6'>
                        <h3 className='h3 pb-5 gradient-text w-fit'>Our Story</h3>
                        <p className='p'>
                            Founded in 1988, our journey began with the KAZ Group, pioneering IT outsourcing
                            services across the Asia Pacific well before the advent of Amazon Web Services and Azure.
                            We provided critical business process outsourcing, notably supporting Australia's premier superannuation funds.
                            {/* Following our successful listing on the ASX and subsequent acquisition by Telstra, we have started a new company,
                            assembled a new team of top AI practitioners to create Kazacos AI. */}
                        </p>
                    </div>
                </div>
                <div className='flex-1'>
                    <img className='w-full h-full max-h-[300px] md:max-h-[400px] object-cover rounded-t-[38px] md:rounded-r-[38px] md:rounded-tl-[0px]' src='images/server-room-image.jpg' alt='Server Room'/>
                </div>
            </div>
            {/* our goal section */}
            <div className='flex flex-col md:flex-row bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mb-6 md:h-[400px]'>
                <div className='flex-1'>
                    <img className='w-full h-full max-h-[300px] md:max-h-[400px] object-cover rounded-t-[38px] md:rounded-l-[38px] md:rounded-tr-[0px]' src='images/teamwork_kpower.jpg' alt='Motherboard with CPU'/>
                </div>
                <div className='flex-1 flex flex-col justify-center'>
                    <div className='max-w-[620px] mx-auto p-6'>
                        <h3 className='h3 pb-5 gradient-text w-fit'>Our Partners</h3>
                        <p className='p'>
                            We empower Your Angel to provide life-changing assistance to children in need through the NAO for Kids program.
                            <br /><br />
                            Additionally, we support Kazacos AI by providing electronic engineers resources for the development of their Magic Story Builder, an interactive screen-free learning platform for children.
                        </p>
                    </div>
                </div>
            </div>
            {/* kpower section */}
            {/* <div className='flex flex-col-reverse md:flex-row bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mb-6 md:h-[400px]'>
                <div className='flex-1 flex flex-col justify-center'>
                    <div className='max-w-[620px] mx-auto p-6'>
                        <h3 className='h3 pb-5 gradient-text w-fit'>Collaboration with KPower</h3>
                        <p className='p'>
                            Kazacos AI partners with KPower, a leading provider of skilled professionals. KPower supplies talented employees to numerous companies, including Kazacos AI. This collaboration enhances our team's capabilities, ensuring we have access to top-tier talent for developing and delivering innovative AI solutions. Our partnership with KPower is crucial in maintaining our position at the forefront of AI technology and empowering businesses with advanced expertise.
                        </p>
                    </div>
                </div>
                <div className='flex-1'>
                    <img className='w-full h-full max-h-[300px] md:max-h-[400px] object-cover rounded-t-[38px] md:rounded-r-[38px] md:rounded-tl-[0px]' src='images/teamwork_kpower.jpg' alt='Server Room'/>
                </div>
            </div> */}
        </section>
    )
}

export default About
