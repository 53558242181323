import React, { useEffect, useState } from 'react'
import HeroShape from './HeroShape'

const Welcome = () => {

    return (
        <section className="container relative min-h-[calc(100vh-160px)] flex flex-col md:flex-row items-center justify-center md:gap-10 mt-20 md:my-0">
            <div className='flex-1 flex flex-col items-center md:items-start gap-[15px] md:gap-[30px] lg:gap-[35px] xl:gap-[40px] md:pl-[30px] lg:pl-[50px]'>
                <div>
                    <p className="hero-p text-center md:text-left text-kaz-green">Welcome to</p>
                    <h1 className="hero-h1 md:whitespace-nowrap -mt-2 lg:-mt-3 text-center md:text-left text-kaz-green">KPower</h1>
                </div>
                <p className="mt-4 hero-p max-w-[560px] text-center md:text-left">Enabling companies to thrive and achieve their goals across diverse areas of work</p>
                <div className='flex flex-col items-center md:flex-row gap-[20px] mt-8'>
                    <a href="#partners">
                        <button className='btn btn-primary lg:text-[20px] xl:text-[22px]'>Learn More</button>
                    </a>
                    {/* <a href="#partners">
                        <button className='btn btn-ghost lg:text-[20px] xl:text-[22px]'>Learn More</button>
                    </a> */}
                </div>
            </div>
            <HeroShape />
        </section>
    )
}

export default Welcome
