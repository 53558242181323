import React, { useEffect, useState } from 'react'
import GlowBackground from './components/GlowBackground'
import ScrollToTopButton from './components/ScrollToTopButton'
import Navbar from './components/Navbar'
import Welcome from './components/Welcome'
import Partners from './components/Partners'
import ServicesTitle from './components/ServicesTitle'
import ServicesSummary from './components/ServicesSummary'
import FinancialServices from './components/FinancialServices'
import MarketingServices from './components/MarketingServices'
import Toolkit from './components/Toolkit'
import Products from './components/Products'
import Faq from './components/Faq'
import About from './components/About'
import Team from './components/Team'
import FinalCallToAction from './components/FinalCallToAction'
import ContactUs from './components/ContactUs'
import Footer from './components/Footer'
import './index.css'

function App() {

    return (
        <div className='flex flex-col items-center py-[20px] overflow-hidden'>
            {/* <GlowBackground /> */}
            {/* <ScrollToTopButton /> */}
            <Navbar />
            <Welcome />
            <Partners />
            <ServicesTitle />
            {/* <ServicesSummary /> */}
            {/* <FinancialServices /> */}
            {/* <MarketingServices /> */}
            {/* <Toolkit /> */}
            {/* <Products /> */}
            {/* <Faq /> */}
            <About />
            {/* <Team /> */}
            {/* <FinalCallToAction /> */}
            {/* <ContactUs /> */}
            <Footer />
        </div>
    )
}

export default App
