import React from 'react'

const ServicesTitle = () => {
    return (
        <section className="container relative my-[90px] md:my-[160px]" id="services-title">
            <div className='z-[-1] w-[350px] h-[350px] xsm:w-[500px] xsm:h-[450px] md:w-[450px] md:h-[600px] lg:w-[700px] lg:h-[700px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-kaz-blue bg-opacity-35 rounded-full blur-[60px] xsm:blur-[80px] md:blur-[100px] lg:blur-[120px]' />
            <h1 className='h1-large text-center mb-10'>Powering <span className='text-kaz-green'>Innovation</span> with our Experts</h1>
            <p className='p-large text-center max-w-[920px] mx-auto'>
                {/* Your gateway to elite talent in engineering, the arts, and beyond. We connect visionary companies with the brightest minds to drive technological advancement. */}
                <div className="max-w-4xl mx-auto text-lg text-gray-700 leading-relaxed">
                <p className="mb-6">
                    At KPower, we have experts in a diverse range of fields, from electronics engineering, to circus performance, to early childhood education.

                    Our mission is to bring together top-tier expertise with visionary projects, ensuring that groundbreaking ideas have the human resources they need to flourish. Whether advancing robotics, artificial intelligence, or educational initiatives, we connect the best minds with the most forward-thinking opportunities.
                    <br />
                    </p>
                    {/* At KPower, we're not just another resource company – we're experts in various areas, from electronics engineering, to the art of early childhood education. Our mission is to bring together top-tier expertise with visionary projects, ensuring that groundbreaking ideas have the human resources they need to flourish. Whether advancing robotics, artificial intelligence, or educational initiatives, we connect the best minds with the most forward-thinking opportunities.</p>  */}
                <p>With over 30 years of experience and a deep understanding of these dynamic fields, we've honed our ability to identify not just skill sets, but the unique blend of expertise, passion, and vision that drives true progress. When you partner with KPower, you're not just accessing resources – you're building the diverse teams that will shape the future.</p>

                </div>
            </p>
        </section>
    )
}

export default ServicesTitle
